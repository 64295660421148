import React from 'react';

import { DataType, PageSectionsData } from '../data';
import AboutSection from './about-section';
import BlogSection from './blog-section';
import BlogSummarySection from './blog-summary-section';
import CtaSection from './cta-section';
import EmbeddedMapSection from './embedded-map-section';
import FormSection from './form-section';
import HeroSection from './hero-section';
import InvestmentsReferencesSection from './investments-references-section';
import NumbersSection from './numbers-section';
import PartnersSection from './partners-section';
import RealEstatesSection from './real-estates-section';
import RealEstatesSummarySection from './real-estates-summary-section';
import SimpleSection from './simple-section';
import TestimonialsSection from './testimonials-section';
import TilesSection from './tiles-section';

interface PageProps {
  data?: any;
}

const SECTIONS_COMPONENTS: {
  [TType in PageSectionsData['type']]: React.FC<{ id: string }>;
} = {
  [DataType.HeroSection]: HeroSection,
  [DataType.CtaSection]: CtaSection,
  // [DataType.CarouselSection]: CarouselSection,
  [DataType.NumbersSection]: NumbersSection,
  [DataType.TestimonialsSection]: TestimonialsSection,
  [DataType.TilesSection]: TilesSection,
  [DataType.PartnersSection]: PartnersSection,
  [DataType.SimpleSection]: SimpleSection,
  [DataType.BlogSection]: BlogSection,
  [DataType.AboutSection]: AboutSection,
  [DataType.BlogSummarySection]: BlogSummarySection,
  [DataType.RealEstatesSummarySection]: RealEstatesSummarySection,
  [DataType.FormSection]: FormSection,
  [DataType.RealEstatesSection]: RealEstatesSection,
  [DataType.InvestmentsReferencesSection]: InvestmentsReferencesSection,
  [DataType.EmbeddedMapSection]: EmbeddedMapSection,
};

const Page: React.FC<PageProps> = ({ data }) => (
  <>
    {data.sections?.map((section, index) => {
      const SectionComponent = SECTIONS_COMPONENTS[section.type];

      return <SectionComponent id={section.id} key={index} />;
    })}
  </>
);

export default Page;
