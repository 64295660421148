import React from 'react';

const QuoteIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={110}
    height={81}
    viewBox="0 0 110 81"
    fill="none"
    className="quote-icon"
  >
    <path
      opacity="0.5"
      d="M64.0324 80.3867V40.3867L93.7099 0.386719H109.194L83.3873 40.3867H104.032V80.3867H64.0324ZM0.806641 80.3867V40.3867L30.4841 0.386719H45.9679L20.1615 40.3867H40.8066V80.3867H0.806641Z"
      fill="#344258"
    />
  </svg>
);

export default QuoteIcon;
