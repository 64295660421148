import '../styles/hero-section.scss';

import { Carousel as AntdCarousel, Typography } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { memo, useRef, useState } from 'react';

import { CAROUSEL_AUTOPLAY_TIMEOUT } from '../config';
import { HeroSectionCarouselItem, useHeroSectionData } from '../data';
import { ContentfulRichText } from '../types';
import { classNameMap } from '../utils';
import Image from './image';
import {
  Box,
  Container,
  LayoutBreakpoint,
  Section,
  useBreakpoint,
} from './layout';

interface HeroSectionProps {
  id: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ id }) => {
  const { data } = useHeroSectionData(id);
  const isMd = useBreakpoint(LayoutBreakpoint.MD);
  const [selectedSlide, setSelectedSlide] = useState<number>(0);
  const carouselRef = useRef<CarouselRef>();

  const shouldEnableSlider = data.carouselItems.length > 1;

  const makeTitle = (titleObject: ContentfulRichText) =>
    JSON.parse(titleObject.raw)
      .content.map(item =>
        item.content
          .map(item => item.value)
          .filter(item => item !== '' && item !== undefined),
      )
      .join('\n');

  const makeHeroSectionContent = (carouselItem: HeroSectionCarouselItem) => (
    <Container>
      <Box
        className="hero-section__parallax-box"
        justify="center"
        direction="column"
      >
        {carouselItem.title && (
          <Typography.Title level={2}>
            {makeTitle(carouselItem.title)}
          </Typography.Title>
        )}
      </Box>
    </Container>
  );

  const makeHeroSectionImage = (carouselItem: HeroSectionCarouselItem) => (
    <Image
      id={
        isMd
          ? carouselItem.desktopImage.id
          : carouselItem.mobileImage?.id ?? carouselItem.desktopImage.id
      }
    />
  );

  return (
    <Section noPadding className="hero-section">
      {shouldEnableSlider ? (
        <>
          <AntdCarousel
            autoplay
            autoplaySpeed={CAROUSEL_AUTOPLAY_TIMEOUT}
            dots={false}
            ref={carouselRef}
            afterChange={currentSlide => setSelectedSlide(currentSlide)}
          >
            {data.carouselItems.map((carouselItem, index) => (
              <div className="carousel__slide" key={index}>
                {makeHeroSectionContent(carouselItem)}
                {makeHeroSectionImage(carouselItem)}
              </div>
            ))}
          </AntdCarousel>

          <Container style={{ position: 'relative' }}>
            <Box
              justify="center"
              align="middle"
              className="carousel-controls-buttons"
            >
              {data.carouselItems.map((_, index) => (
                <button
                  key={index}
                  className={classNameMap({
                    'carousel-controls-button': true,
                    'carousel-controls-button--active': index === selectedSlide,
                  })}
                  onClick={() => carouselRef?.current?.goTo(index)}
                  aria-label={`hero section controls ${index}`}
                />
              ))}
            </Box>
          </Container>
        </>
      ) : (
        <>
          {makeHeroSectionContent(data.carouselItems[0])}
          <Box justify="center" align="middle" className="hero-section__image">
            {makeHeroSectionImage(data.carouselItems[0])}
          </Box>
        </>
      )}
    </Section>
  );
};

export default memo(HeroSection);
