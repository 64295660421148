import '../styles/form-section.scss';

import React, { memo } from 'react';

import { useFormSectionData } from '../data';
import SectionHeading from './common/section-heading';
import Form from './form';
import { Box, Container, Section } from './layout';
import TilesSection from './tiles-section';
import { richTextToReactComponents } from './utils';

interface FormSectionProps {
  id: string;
}

const FormSection: React.FC<FormSectionProps> = ({ id }) => {
  const { data } = useFormSectionData(id);

  return (
    <Section className="form-section">
      <Container>
        <SectionHeading align="center" title={data.title} />

        {data.description && (
          <Box
            justify="center"
            direction="column"
            align="middle"
            className="form-section__box-description"
          >
            {richTextToReactComponents(data.description)}
          </Box>
        )}

        {data.content && (
          <Box className="form-section__box-description">
            {richTextToReactComponents(data.content)}
          </Box>
        )}

        {data.tiles && <TilesSection id={data.tiles.id} />}

        <Form id={data.form.id} />
      </Container>
    </Section>
  );
};

export default memo(FormSection);
