import '../styles/testimonials-section.scss';

import { Typography } from 'antd';
import React, { memo, useMemo } from 'react';

import { useTestimonialsSectionData } from '../data';
import Carousel from './carousel';
import SectionHeading from './common/section-heading';
import QuoteIcon from './icons/quote';
import { Box, Container, Section } from './layout';
import { richTextToReactComponents } from './utils';

export interface TestimonialsSectionProps {
  id: string;
}

const TestimonialsSection: React.FC<TestimonialsSectionProps> = ({ id }) => {
  const { data } = useTestimonialsSectionData(id);

  const slides = useMemo(
    () =>
      data.items.map(item => ({
        component: (
          <>
            <QuoteIcon />

            <div className="testimonials-section__description">
              {richTextToReactComponents(item.description)}
            </div>

            <div className="rating">
              <span>☆</span>
              <span>☆</span>
              <span>☆</span>
              <span>☆</span>
              <span>☆</span>
            </div>

            <Box className="testimonials-section__signature">
              <Typography.Paragraph className="testimonials-section__author">
                {item.author}
              </Typography.Paragraph>

              <div className="testimonials-section__circle" />

              <Typography.Paragraph className="testimonials-section__location">
                {item.location}
              </Typography.Paragraph>
            </Box>
          </>
        ),
      })),
    [data],
  );

  return (
    <Section className="testimonials-section">
      <Container>
        <SectionHeading title={data.title} align="center" />
        <Carousel slides={slides} />
      </Container>
    </Section>
  );
};

export default memo(TestimonialsSection);
