import '../styles/numbers-section.scss';

import { Typography } from 'antd';
import React, { memo } from 'react';

import { useNumbersSectionData } from '../data';
import CounterUp from './common/counter-up';
import SectionHeading from './common/section-heading';
import { Image } from './image';
import { Box, Col, Container, Row, Section } from './layout';

interface NumbersSectionProps {
  id: string;
}

export const NumbersSection: React.FC<NumbersSectionProps> = ({ id }) => {
  const { data } = useNumbersSectionData(id);

  return (
    <Section className="numbers-section">
      <Container>
        <SectionHeading title={data.title} align="center" />
        <Row gutters="4rem">
          {data.items.map((item, index) => (
            <Col span={{ sm: 24, lg: 8 }} key={index} center>
              <Box
                justify="center"
                align="middle"
                direction="column"
                className="numbers-section__box"
              >
                <Image id={item.icon.id} />

                <Typography.Paragraph
                  strong
                  className="numbers-section__box-count"
                >
                  <CounterUp
                    end={item.count ?? 0}
                    formattingFn={count =>
                      `${item.beforeUnit ?? ''}${new Intl.NumberFormat()
                        .format(count)
                        .replace(/,/g, ' ')}${item.afterUnit ?? ''}`
                    }
                  />
                </Typography.Paragraph>

                <Typography.Paragraph className="numbers-section__box-title">
                  {item.title}
                </Typography.Paragraph>
              </Box>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default memo(NumbersSection);
