import '../styles/about-section.scss';

import { Collapse, Typography } from 'antd';
import React, { memo } from 'react';

import { useAboutSectionData } from '../data';
import { win } from '../fragments/window';
import FlipCard from './common/flip-card';
import SectionHeading from './common/section-heading';
import DropdownArrowIcon from './icons/dropdown-arrow';
import { Image } from './image';
import { Box, Col, Container, LayoutBreakpoint, Row, Section } from './layout';
import useBreakpoint from './layout/hooks/useBreakpoint';
import { richTextToReactComponents } from './utils';

interface AboutSectionProps {
  id: string;
}

const AboutSection: React.FC<AboutSectionProps> = ({ id }) => {
  const { data } = useAboutSectionData(id);
  const isMd = useBreakpoint(LayoutBreakpoint.MD);

  const isSafari = /^((?!chrome|android).)*safari/i.test(
    win?.navigator.userAgent,
  );

  const makeDesktopComponent = item =>
    isSafari ? (
      <Box className="about-section-item__box-safari-box">
        <Image id={item.data.image.id} />
        <div className="about-section-item__box-safari-box-layer">
          {richTextToReactComponents(item.data.description)}
        </div>
      </Box>
    ) : (
      <>
        <FlipCard
          front={
            <>
              <Image id={item.data.image.id} />
            </>
          }
          back={<>{richTextToReactComponents(item.data.description)}</>}
        />
      </>
    );

  return (
    <Section className="about-section">
      <Container>
        <SectionHeading title={data.title} description={data.description} />
        <Row gutters={{ sm: '1rem', md: '6rem' }}>
          {data.items.map((item, index) => (
            <Col
              key={index}
              span={{ sm: 24, md: index === 0 ? 24 : 12 }}
              center
            >
              <Box className="about-section-item__box">
                {isMd ? (
                  <>
                    {makeDesktopComponent(item)}
                    <Box
                      justify="center"
                      className="about-section-item__box-footer"
                    >
                      <Typography.Title level={4}>
                        {item.data.fullName}
                      </Typography.Title>
                    </Box>
                  </>
                ) : (
                  <>
                    <Collapse ghost>
                      <Collapse.Panel
                        key={item.data.fullName}
                        showArrow={false}
                        header={
                          <>
                            <Image id={item.data.image.id} />
                            <Box
                              justify="center"
                              className="about-section-item__box-footer"
                            >
                              <Typography.Title level={4}>
                                {item.data.fullName}
                                <DropdownArrowIcon />
                              </Typography.Title>
                            </Box>
                          </>
                        }
                      >
                        {richTextToReactComponents(item.data.description)}
                      </Collapse.Panel>
                    </Collapse>
                  </>
                )}
              </Box>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default memo(AboutSection);
