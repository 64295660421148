import React, { memo } from 'react';

import { useBlogPostsPartialsData, useBlogSummarySectionData } from '../data';
import BlogPostItem from './blog-post-item';
import Button from './button';
import SectionHeading from './common/section-heading';
import { Col, Container, Row, Section } from './layout';

interface BlogSummarySectionProps {
  id: string;
}

const DEFAULT_ITEMS_COUNT = 3;

const BlogSummarySection: React.FC<BlogSummarySectionProps> = ({ id }) => {
  const { data } = useBlogSummarySectionData(id);
  const blogPosts = useBlogPostsPartialsData().slice(
    0,
    data.itemsCount ?? DEFAULT_ITEMS_COUNT,
  );

  return (
    <>
      <Section>
        <Container>
          <SectionHeading align="center" title={data.title} />
          <Row gutters="4rem">
            {blogPosts.map((post, index) => (
              <Col key={index} span={{ sm: 24, md: 8 }}>
                <BlogPostItem item={post} />
              </Col>
            ))}
          </Row>
          {data.button && (
            <Button
              id={data.button.id}
              defaultProps={{ type: 'primary', style: { margin: 'auto' } }}
            />
          )}
        </Container>
      </Section>
    </>
  );
};

export default memo(BlogSummarySection);
