import '../styles/investments-references-section.scss';

import { Typography } from 'antd';
import React, { memo } from 'react';

import {
  InvestmentsReferencesSectionItem,
  useInvestmentsReferencesSectionData,
} from '../data';
import SectionHeading from './common/section-heading';
import Image from './image';
import { Box, Col, Container, Row, Section } from './layout';
import { richTextToReactComponents } from './utils';

interface InvestmentsReferencesSectionProps {
  id: string;
}

const InvestmentsReferencesSection: React.FC<InvestmentsReferencesSectionProps> = ({
  id,
}) => {
  const { data } = useInvestmentsReferencesSectionData(id);

  const makeTileBoxContent = (item: InvestmentsReferencesSectionItem) => (
    <Box direction="column" className="investments-references-section__box">
      <Box className="investments-references-section__box-image">
        <Image id={item.image.id} />
      </Box>

      <Box
        justify="space-between"
        align="middle"
        direction="column"
        className="investments-references-section__box-body"
      >
        <Box className="investments-references-section__box-body-header">
          <Typography.Paragraph className="investments-references-section__box-body-state">
            {item.state}
          </Typography.Paragraph>
          {richTextToReactComponents(item.title)}
        </Box>
        <Box className="investments-references-section__box-body-footer">
          {richTextToReactComponents(item.description)}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Section className="investments-references-section">
      <Container>
        <SectionHeading title={data.title} align="center" />
        <Row gutters="4rem">
          {data.items.map((item, index) => (
            <Col span={{ sm: 24, md: 8 }} key={index}>
              {makeTileBoxContent(item)}
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default memo(InvestmentsReferencesSection);
