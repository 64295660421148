import '../styles/real-estates-section.scss';

import { Pagination, Typography } from 'antd';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import {
  FormFieldType,
  RealEstateDetailsPartial,
  useRealEstateDetailsPartialsData,
  useRealEstatesSectionData,
} from '../data';
import { useRealEstateTypeData } from '../data/hooks/use-real-estate-type-data';
import { usePrevious } from '../hooks';
import { isEmpty } from '../utils';
import SectionHeading from './common/section-heading';
import TilesInput from './common/tiles-input';
import { Box, Col, Container, Row, Section } from './layout';
import RealEstateItem from './real-estate-item';

const DEFAULT_PAGINATED_PAGE = 0;
const DEFAULT_PAGINATED_PAGE_SIZE = 10;

interface RealEstatesSectionProps {
  id: string;
}

const RealEstatesSection: React.FC<RealEstatesSectionProps> = ({ id }) => {
  const { data: realEstatesSectionData } = useRealEstatesSectionData(id);
  const realEstates = useRealEstateDetailsPartialsData();
  const realEstatesTypes = useRealEstateTypeData().list;
  const paginatedPageSize =
    realEstatesSectionData.pageSize ?? DEFAULT_PAGINATED_PAGE_SIZE;

  const [paginatedPage, setPaginatedPage] = useState(DEFAULT_PAGINATED_PAGE);
  const previousPaginatedPage = usePrevious(paginatedPage);
  const realEstatesContainerElement = useRef<HTMLDivElement>();

  useEffect(() => {
    if (previousPaginatedPage !== paginatedPage) {
      realEstatesContainerElement?.current.scrollIntoView();
    }
  }, [paginatedPage]);
  const [filteredRealEstates, setFilteredRealEstates] = useState<
    RealEstateDetailsPartial[]
  >(realEstates);

  const visibleRealEstates = useMemo(
    () =>
      filteredRealEstates.slice(
        paginatedPage * paginatedPageSize,
        paginatedPage * paginatedPageSize + paginatedPageSize,
      ),
    [filteredRealEstates, paginatedPage, paginatedPageSize],
  );

  const handleFiltersChange = (selectedFilters?: string[]) => {
    const result = isEmpty(selectedFilters)
      ? realEstates
      : realEstates.filter(realEstate =>
          selectedFilters.includes(realEstate.type.data.name),
        );

    setFilteredRealEstates(result);
  };

  return (
    <>
      <div ref={realEstatesContainerElement}>
        <Section className="real-estates-section">
          <Container>
            <SectionHeading
              title={realEstatesSectionData.title}
              description={realEstatesSectionData.description}
            />

            <Box className="real-estates-section__filters">
              <Typography.Title level={4}>
                Nach Eigenschaftstyp filtern
              </Typography.Title>
              <TilesInput
                type={FormFieldType.Checkbox}
                options={realEstatesTypes.map(option => ({
                  label: option.data.name,
                  value: option.data.name,
                  icon: option.data.icon,
                }))}
                onChange={value => handleFiltersChange(value)}
              />
            </Box>

            <Row gutters="4rem">
              {visibleRealEstates.map((item, index) => (
                <Col key={index} span={{ sm: 24, md: 8 }}>
                  <RealEstateItem item={item} fullWidth />
                </Col>
              ))}
            </Row>

            <Box justify="center">
              <Pagination
                showSizeChanger={false}
                defaultCurrent={paginatedPage + 1}
                total={filteredRealEstates.length}
                onChange={currentPageNumber =>
                  setPaginatedPage(currentPageNumber - 1)
                }
              />
            </Box>
          </Container>
        </Section>
      </div>
    </>
  );
};

export default memo(RealEstatesSection);
