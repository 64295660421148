import React, { memo } from 'react';

import { useEmbeddedMapSectionData } from '../data';
import { Map } from './common/map';
import { Container, Section } from './layout';

interface EmbeddedMapSectionProps {
  id: string;
}

const EmbeddedMapSection: React.FC<EmbeddedMapSectionProps> = ({ id }) => {
  const { data } = useEmbeddedMapSectionData(id);

  return (
    <Section>
      <Container>
        <Map
          coordinates={{
            lat: data.location.lat,
            lng: data.location.lon,
          }}
          showMarker
          mapInstanceOptions={{
            zoom: 15,
            pitch: 60,
            bearing: -60,
          }}
        />
      </Container>
    </Section>
  );
};

export default memo(EmbeddedMapSection);
