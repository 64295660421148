import React, { memo } from 'react';

import { useRealEstateDetailsPartialsData } from '../data/hooks/use-real-estate-details-partial';
import { useRealEstatesSummarySectionData } from '../data/hooks/use-real-estates-summary-section-data';
import Button from './button';
import SectionHeading from './common/section-heading';
import { Col, Container, Row, Section } from './layout';
import RealEstateItem from './real-estate-item';

interface RealEstatesSummarySectionProps {
  id: string;
}

const DEFAULT_ITEMS_COUNT = 4;

const RealEstatesSummarySection: React.FC<RealEstatesSummarySectionProps> = ({
  id,
}) => {
  const { data } = useRealEstatesSummarySectionData(id);

  const resolvedItemsCount = data.itemsCount ?? DEFAULT_ITEMS_COUNT;

  const realEstates = useRealEstateDetailsPartialsData().slice(
    0,
    resolvedItemsCount,
  );

  return (
    <Section>
      <Container>
        <SectionHeading
          align="center"
          title={data.title}
          description={data.description}
        />
        <Row gutters="4rem">
          {realEstates.map((item, index) => (
            <Col key={index} span={{ sm: 24, md: 24 / resolvedItemsCount }}>
              <RealEstateItem item={item} fullWidth />
            </Col>
          ))}
        </Row>
        {data.button && (
          <Button
            id={data.button.id}
            defaultProps={{ type: 'primary', style: { margin: 'auto' } }}
          />
        )}
      </Container>
    </Section>
  );
};

export default memo(RealEstatesSummarySection);
