import '../styles/blog-section.scss';

import { Button, Input, Pagination } from 'antd';
import Fuse from 'fuse.js';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import {
  BlogPostPartial,
  useBlogPostsPartialsData,
  useBlogSectionData,
} from '../data';
import { usePrevious } from '../hooks';
import { isEmpty } from '../utils';
import BlogPostItem, { BlogPostItemContent } from './blog-post-item';
import SectionHeading from './common/section-heading';
import CloseIcon from './icons/close';
import LoupeIcon from './icons/loupe';
import Image from './image';
import { Box, Col, Container, Row, Section } from './layout';

const DEFAULT_PAGINATED_PAGE = 0;
const DEFAULT_PAGINATED_PAGE_SIZE = 10;

interface BlogSectionProps {
  id: string;
}

const BlogSection: React.FC<BlogSectionProps> = ({ id }) => {
  const { data: blogSectionData } = useBlogSectionData(id);
  const posts = useBlogPostsPartialsData().map(post => ({
    ...post,
    // Parse data to object, for the searching purpose
    content: { ...post.content, raw: JSON.parse(post.content.raw) },
  }));
  const paginatedPageSize =
    blogSectionData.pageSize ?? DEFAULT_PAGINATED_PAGE_SIZE;

  const [paginatedPage, setPaginatedPage] = useState(DEFAULT_PAGINATED_PAGE);
  const previousPaginatedPage = usePrevious(paginatedPage);
  const blogItemsContainerElement = useRef<HTMLDivElement>();

  useEffect(() => {
    if (previousPaginatedPage !== paginatedPage) {
      blogItemsContainerElement?.current.scrollIntoView();
    }
  }, [paginatedPage]);

  const [searchedPosts, setSearchedPosts] = useState<BlogPostPartial[]>(posts);
  const [searchedValue, setSearchedValue] = useState<string>();
  const fuseInstance = useMemo(() => {
    const fuseOptions = {
      threshold: 0.1,
      distance: 1000,
      keys: [
        'content.raw.content.content.value',
        'date',
        'description',
        'subtitle',
        'title',
      ],
    };

    return new Fuse(posts, fuseOptions);
  }, [posts]);

  const visiblePosts = useMemo(
    () =>
      searchedPosts.slice(
        paginatedPage * paginatedPageSize,
        paginatedPage * paginatedPageSize + paginatedPageSize,
      ),
    [searchedPosts, paginatedPage, paginatedPageSize],
  );

  const hasSearchEnabled = !isEmpty(searchedValue);

  const featured =
    paginatedPage === DEFAULT_PAGINATED_PAGE && !hasSearchEnabled
      ? visiblePosts[0]
      : undefined;
  const list =
    paginatedPage === DEFAULT_PAGINATED_PAGE && !hasSearchEnabled
      ? visiblePosts.slice(1)
      : visiblePosts;

  const handleSearchChange = (inputValue?: string) => {
    if (!isEmpty(inputValue)) {
      const searchedResult = fuseInstance
        .search(inputValue)
        .map(({ item }) => item);
      setSearchedValue(inputValue);
      setSearchedPosts(searchedResult);
    } else {
      setSearchedValue('');
      setSearchedPosts(posts);
    }
  };

  return (
    <>
      {blogSectionData.title && (
        <Section>
          <SectionHeading align="center" title={blogSectionData.title} />
        </Section>
      )}

      {featured && (
        <Section className="blog-featured-section">
          <Container>
            <Row>
              <Col span={{ sm: 24, md: 12 }}>
                <Image id={featured.image.id} />
              </Col>
              <Col offset={{ md: 1 }} span={{ sm: 24, md: 11 }}>
                <Box justify="center" direction="column" fullHeight>
                  <BlogPostItemContent item={featured} />
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      )}

      <div ref={blogItemsContainerElement}>
        <Section>
          <Container>
            <Box justify="center">
              <Input
                className="blog-search-input"
                bordered={false}
                placeholder="Suche nach Einträgen"
                value={searchedValue}
                suffix={
                  hasSearchEnabled ? (
                    <Button type="text" onClick={() => handleSearchChange()}>
                      <CloseIcon />
                    </Button>
                  ) : (
                    <Button type="text">
                      <LoupeIcon />
                    </Button>
                  )
                }
                onChange={event => handleSearchChange(event.target.value)}
              />
            </Box>
            <Row gutters="4rem">
              {list.map((post, index) => (
                <Col key={index} span={{ sm: 24, md: 8 }}>
                  <BlogPostItem item={post} />
                </Col>
              ))}
            </Row>
            <Box justify="center">
              <Pagination
                showSizeChanger={false}
                defaultCurrent={paginatedPage + 1}
                total={searchedPosts.length}
                onChange={currentPageNumber =>
                  setPaginatedPage(currentPageNumber - 1)
                }
              />
            </Box>
          </Container>
        </Section>
      </div>
    </>
  );
};

export default memo(BlogSection);
