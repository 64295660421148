import '../styles/tiles-section.scss';

import { Typography } from 'antd';
import React, { memo } from 'react';

import { TilesSectionItem, useTilesSectionData } from '../data';
import Button from './button';
import SectionHeading from './common/section-heading';
import Image from './image';
import { Box, Col, Container, Row, Section } from './layout';
import { richTextToReactComponents } from './utils';

interface TilesSectionProps {
  id: string;
}

export const TilesSection: React.FC<TilesSectionProps> = ({ id }) => {
  const { data } = useTilesSectionData(id);

  const makeTileBoxContent = (item: TilesSectionItem, index: number) => (
    <Box direction="column" className="tiles-section__box">
      <Box
        justify="space-between"
        align="middle"
        className="tiles-section__box-header"
      >
        {data.showItemsCount && (
          <Typography.Paragraph className="tiles-section__box-index">
            {index + 1}.
          </Typography.Paragraph>
        )}
        {item.icon?.id && (
          <Box justify="end">
            <Image id={item.icon.id} />
          </Box>
        )}
      </Box>

      <Box className="tiles-section__box-body">
        <Typography.Title level={4} className="tiles-section__box-body-title">
          {item.title}
        </Typography.Title>

        {richTextToReactComponents(item.description)}
      </Box>

      {item.button?.id && (
        <Box className="tiles-section__box-footer">
          <Button
            id={item.button.id}
            defaultProps={{
              type: 'dashed',
            }}
          />
        </Box>
      )}
    </Box>
  );

  return (
    <Section className="tiles-section">
      <Container>
        <SectionHeading
          title={data.title}
          description={data.description}
          align="center"
        />
        <Row gutters="4rem">
          {data.items.map((item, index) => (
            <Col span={{ sm: 24, lg: 12 }} key={index}>
              {makeTileBoxContent(item, index)}
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default memo(TilesSection);
