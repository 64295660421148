import React, { memo } from 'react';
import { CountUpProps, default as CountUp } from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const CounterUp: React.FC<CountUpProps> = props => {
  return (
    <CountUp start={0} {...props}>
      {({ countUpRef, start }) => (
        <VisibilitySensor
          onChange={visible => {
            if (visible === false || start === undefined) {
              return;
            }

            start();
          }}
          delayedCall
        >
          <span ref={countUpRef} />
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

export default memo(CounterUp);
