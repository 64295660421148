import React from 'react';

import Boilerplate from '../components/boilerplate';
import Page from '../components/page';
import { PageLocalContext, PageLocalContextInterface } from '../context';
import { usePageData } from '../data';
import { Template } from '../types/templates';

const PageTemplate: Template<PageLocalContextInterface> = ({
  pageContext: { id, pageId },
}) => {
  const { data } = usePageData(id);

  return (
    <PageLocalContext.Provider value={{ id, pageId, data }}>
      <Boilerplate seoId={data.seo.id} pageId={id} pageTheme={data.pageTheme}>
        <Page data={data} />
      </Boilerplate>
    </PageLocalContext.Provider>
  );
};

export default PageTemplate;
