import '../styles/simple-section.scss';

import React, { memo } from 'react';

import { useSimpleSectionData } from '../data/hooks/use-simple-section-data';
import { classNameMap } from '../utils/tsx';
import SectionHeading from './common/section-heading';
import { Box, Container, Section } from './layout';

export interface SimpleSectionProps {
  id: string;
}

const SimpleSection: React.FC<SimpleSectionProps> = ({ id }) => {
  const { data } = useSimpleSectionData(id);

  return (
    <Section className="simple-section">
      <Container>
        <Box
          direction="column"
          className={classNameMap({
            'simple-section__box': true,
            [`simple-section__box--${
              data?.textAlign?.toLowerCase() ?? 'center'
            }`]: true,
          })}
        >
          <SectionHeading title={data.title} description={data.content} />
        </Box>
      </Container>
    </Section>
  );
};

export default memo(SimpleSection);
